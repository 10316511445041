<template>
  <div class="px-1">
    <h4 class="mt-4">
      {{ $getVisibleNames("manual.matriz_3", false, "Matriz 3") }}: Tributación
      de las
      {{ $getVisibleNames("mesh.egressprofilematter", true, "Asignaturas") }} al
      {{ $getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso") }}
      <!-- Perfil de Egreso -->
    </h4>

    <!-- <div class="d-flex justify-content-center">
      <div class="w-25">
        <b-form-group
          label="Ciclo"
          label-cols="0"
          label-cols-sm="2"
          class="text-center wa"
        >
          <b-form-select
            :options="cycles"
            v-model="cycle_id"
            text-field="name"
            value-field="id"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </div>
    </div> -->
    <template>
      <!-- v-if="cycle_id" -->
      <Matrix3 :egress_profile_id="egress_profile_id"></Matrix3>
      <!-- :cycle_id="cycle_id" -->
      <div class="matrix3-report-container mt-3">
        <div
          v-for="competenceType in competenceTypes"
          v-bind:key="competenceType.id"
        >
          <div v-if="reportCompetences(competenceType.id).length">
            <div class="competences">
              <strong>{{ competenceType.name.toUpperCase() }}</strong>
            </div>
            <table class="competences-table">
              <thead class="competence-header">
                <th class="competence-caption-title">Definición:</th>
                <th
                  class="competence-caption-title"
                  v-if="
                    egressProfile
                      ? egressProfileCycleId.length > 0
                        ? true
                        : false
                      : false
                  "
                >
                  {{
                    egressProfileCycleId.length > 1
                      ? $getVisibleNames(
                          "mesh.cycle",
                          true,
                          "Ciclos Formativos"
                        )
                      : $getVisibleNames("mesh.cycle", false, "Ciclo Formativo")
                  }}
                </th>
                <th
                  class="competence-caption-title"
                  v-if="profileCompetencesMention.length > 0 ? true : false"
                >
                  {{
                    $getVisibleNames(
                      "mesh.egressprofilecompetencemention",
                      profileCompetencesMention.length > 1,
                      "Mención"
                    )
                  }}
                </th>
                <th class="amount-courses pr-3">
                  {{
                    $getVisibleNames(
                      "mesh.egressprofilematter",
                      true,
                      "Asignaturas"
                    )
                  }}
                </th>
                <!-- <th class="amount">Cantidad</th> -->
              </thead>
              <tbody>
                <tr
                  v-for="item in reportCompetences(competenceType.id)"
                  v-bind:key="item.id"
                  class="competences-row"
                >
                  <td class="competence-caption">
                    <div class="d-flex">
                      <SentenceContainer :Sentence="item">
                        <template #order>
                          <span class="mr-1">
                            {{
                              item.profile_cycle
                                ? item.profile_cycle.order + "." + item.order
                                : item.order + "."
                            }}<span
                              v-if="
                                item.sub_order > 0 &&
                                institution &&
                                institution.show_competence_sub_order
                              "
                              >.{{ item.sub_order }}</span
                            >
                          </span>
                        </template>
                      </SentenceContainer>
                      <!-- {{ item.full_sentence }} -->
                    </div>
                  </td>
                  <td
                    class="competence-caption"
                    v-if="
                      egressProfile
                        ? egressProfileCycleId.length > 0
                          ? true
                          : false
                        : false
                    "
                  >
                    {{ cycleName(item.cycle) }}
                  </td>
                  <td
                    class="competence-caption"
                    v-if="profileCompetencesMention.length > 0 ? true : false"
                  >
                    {{ mentionsName(item) }}
                  </td>
                  <td class="amount-courses">
                    <div class="competence-matters-container">
                      <b-button
                        size="sm"
                        v-b-tooltip.v-secondary.hover="
                          item.courses.length > 0 ? 'Ver Detalles' : ''
                        "
                        class="popover-btn"
                        @click="
                          $bvModal.show(`competence-matters-modal-${item.id}`)
                        "
                      >
                        <b-badge variant="secondary"
                          >{{ item.cantidad }}<span class="sr-only"></span
                        ></b-badge>
                      </b-button>
                      <b-modal
                        v-if="item.courses.length > 0"
                        :id="`competence-matters-modal-${item.id}`"
                        size="lg"
                        hide-footer
                      >
                        <template #modal-title>
                          <h6>
                            <span class="m-1 text-justify d-flex">
                              <SentenceContainer :Sentence="item">
                                <template #order>
                                  <span class="mr-1">
                                    {{
                                      item.profile_cycle
                                        ? item.profile_cycle.order +
                                          "." +
                                          item.order
                                        : item.order + "."
                                    }}<span
                                      v-if="
                                        item.sub_order > 0 &&
                                        institution &&
                                        institution.show_competence_sub_order
                                      "
                                      >.{{ item.sub_order }}</span
                                    >
                                  </span>
                                </template>
                              </SentenceContainer>
                              <!-- {{ item.full_sentence }} -->
                            </span>
                          </h6>
                        </template>
                        <b-table-simple responsive small bordered>
                          <b-thead>
                            <b-tr>
                              <b-th class="th-class">Nivel del Plan</b-th>
                              <b-th class="th-class">{{
                                $getVisibleNames(
                                  "mesh.egressprofilematter",
                                  true,
                                  "Asignaturas"
                                )
                              }}</b-th>
                              <b-th class="th-class">
                                {{
                                  $getVisibleNames(
                                    "manual.credits",
                                    true,
                                    "Créditos SCT Totales"
                                  )
                                }}
                              </b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr
                              v-for="egress_profile_matter in egressProfileMatterList.filter(
                                (x) => item.courses.includes(x.id)
                              )"
                              :key="egress_profile_matter.id + '-' + item.id"
                            >
                              <b-td class="text-center">{{
                                egress_profile_matter.plan_level
                              }}</b-td>
                              <b-td>
                                <b-link
                                  :to="{
                                    name: 'MatterProgramView',
                                    params: {
                                      matter_id: egress_profile_matter.id,
                                    },
                                  }"
                                  v-b-tooltip.v-secondary="
                                    `Ir al ${$getVisibleNames(
                                      'manual.matter_program',
                                      false,
                                      'Programa de Asignatura'
                                    )}`
                                  "
                                >
                                  {{
                                    getMatterName(
                                      egress_profile_matter.matter
                                    ).toUpperCase()
                                  }}
                                </b-link>
                              </b-td>
                              <b-td class="text-center">
                                {{
                                  egress_profile_matter.use_matter_values
                                    ? (
                                        matters.find(
                                          (x) =>
                                            x.id == egress_profile_matter.matter
                                        ) || {}
                                      ).credits || 0
                                    : egress_profile_matter.credits
                                }}
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-modal>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div v-if="reportCompetences(null).length">
            <div class="competences">
              <strong>{{
                $getVisibleNames(
                  "mesh.competence",
                  true,
                  "Competencias"
                ).toUpperCase()
              }}</strong>
            </div>
            <table class="competences-table">
              <thead class="competence-header">
                <th class="competence-caption-title">Definición:</th>
                <th
                  class="competence-caption-title"
                  v-if="
                    egressProfile
                      ? egressProfileCycleId.length > 0
                        ? true
                        : false
                      : false
                  "
                >
                  {{
                    egressProfileCycleId.length > 1
                      ? $getVisibleNames(
                          "mesh.cycle",
                          true,
                          "Ciclos Formativos"
                        )
                      : $getVisibleNames("mesh.cycle", false, "Ciclo Formativo")
                  }}
                </th>
                <th
                  class="competence-caption-title"
                  v-if="profileCompetencesMention.length > 0 ? true : false"
                >
                  {{
                    $getVisibleNames(
                      "mesh.egressprofilecompetencemention",
                      profileCompetencesMention.length > 1,
                      "Mención"
                    )
                  }}
                </th>
                <th class="amount-courses pr-3">
                  {{
                    $getVisibleNames(
                      "mesh.egressprofilematter",
                      true,
                      "Asignaturas"
                    )
                  }}
                </th>
                <!-- <th class="amount">Cantidad</th> -->
              </thead>
              <tbody>
                <tr
                  v-for="item in reportCompetences(null)"
                  v-bind:key="item.id"
                  class="competences-row"
                >
                  <td class="competence-caption">
                    <div class="d-flex">
                      <SentenceContainer :Sentence="item">
                        <template #order>
                          <span class="mr-1">
                            {{
                              item.profile_cycle
                                ? item.order + "." + item.profile_cycle.order
                                : item.order + "."
                            }}<span
                              v-if="
                                item.sub_order > 0 &&
                                institution &&
                                institution.show_competence_sub_order
                              "
                              >.{{ item.sub_order }}</span
                            >
                          </span>
                        </template>
                      </SentenceContainer>
                      <!-- {{ item.full_sentence }} -->
                    </div>
                  </td>
                  <td
                    class="competence-caption"
                    v-if="
                      egressProfile
                        ? egressProfileCycleId.length > 0
                          ? true
                          : false
                        : false
                    "
                  >
                    {{ cycleName(item.cycle) }}
                  </td>
                  <td
                    class="competence-caption"
                    v-if="profileCompetencesMention.length > 0 ? true : false"
                  >
                    {{ mentionsName(item) }}
                  </td>
                  <td class="amount-courses">
                    <div class="competence-matters-container">
                      <!-- :id="`popover-button-variant-${item.id}`" -->
                      <b-button
                        size="sm"
                        v-b-tooltip.v-secondary.hover="
                          item.courses.length > 0 ? 'Ver Detalles' : ''
                        "
                        class="popover-btn"
                        @click="
                          $bvModal.show(`competence-matters-modal-2-${item.id}`)
                        "
                      >
                        <b-badge variant="secondary"
                          >{{ item.cantidad }}<span class="sr-only"></span
                        ></b-badge>
                      </b-button>
                      <b-modal
                        v-if="item.courses.length > 0"
                        :id="`competence-matters-modal-2-${item.id}`"
                        size="lg"
                        hide-footer
                      >
                        <template #modal-title>
                          <h6>
                            <span class="m-1 text-justify d-flex">
                              <SentenceContainer :Sentence="item">
                                <template #order>
                                  <span class="mr-1">
                                    {{
                                      item.profile_cycle
                                        ? item.order +
                                          "." +
                                          item.profile_cycle.order
                                        : item.order + "."
                                    }}<span
                                      v-if="
                                        item.sub_order > 0 &&
                                        institution &&
                                        institution.show_competence_sub_order
                                      "
                                      >.{{ item.sub_order }}</span
                                    >
                                  </span>
                                </template>
                              </SentenceContainer>
                              <!-- {{ item.full_sentence }} -->
                            </span>
                          </h6>
                        </template>
                        <b-table-simple responsive small bordered>
                          <b-thead>
                            <b-tr>
                              <b-th class="th-class">Nivel del Plan</b-th>
                              <b-th class="th-class">{{
                                $getVisibleNames(
                                  "mesh.egressprofilematter",
                                  true,
                                  "Asignaturas"
                                )
                              }}</b-th>
                              <b-th class="th-class">
                                {{
                                  $getVisibleNames(
                                    "manual.credits",
                                    true,
                                    "Créditos SCT Totales"
                                  )
                                }}
                              </b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr
                              v-for="egress_profile_matter in egressProfileMatterList.filter(
                                (x) => item.courses.includes(x.id)
                              )"
                              :key="egress_profile_matter.id + '-' + item.id"
                            >
                              <b-td class="text-center">{{
                                egress_profile_matter.plan_level
                              }}</b-td>
                              <b-td>
                                <b-link
                                  :to="{
                                    name: 'MatterProgramView',
                                    params: {
                                      matter_id: egress_profile_matter.id,
                                    },
                                  }"
                                  v-b-tooltip.v-secondary="
                                    `Ir al ${$getVisibleNames(
                                      'manual.matter_program',
                                      false,
                                      'Programa de Asignatura'
                                    )}`
                                  "
                                >
                                  {{
                                    getMatterName(
                                      egress_profile_matter.matter
                                    ).toUpperCase()
                                  }}
                                </b-link>
                              </b-td>
                              <b-td class="text-center">
                                {{
                                  egress_profile_matter.use_matter_values
                                    ? (
                                        matters.find(
                                          (x) =>
                                            x.id == egress_profile_matter.matter
                                        ) || {}
                                      ).credits || 0
                                    : egress_profile_matter.credits
                                }}
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-modal>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <CognitiveColorTable style="width: 35em"></CognitiveColorTable> -->
      </div>
    </template>
    <MeshCustomFab
      :egress_profile_id="egress_profile_id"
      :show_matrix3="false"
      class="noprint"
    ></MeshCustomFab>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "Matrix3View",
  components: {
    MeshCustomFab: () => import("../MeshCustomFab"),
    Matrix3: () => import("./Matrix3"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    // CognitiveColorTable: () =>
    //   import("@/components/reusable/CognitiveColorTable"),
  },
  data() {
    return {
      egress_profile_id: Number(this.$route.params.egress_profile_id),
      // cycle_id: null,
      printing: false,
      institution_id: this.$store.getters.getInstitutionId,
      isActive: false,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      cycles: names.CYCLES,
      formationAreas: names.FORMATION_AREAS,
      matters: names.MATTERS,
      competenceTypes: names.COMPETENCE_TYPES,
      competences: names.COMPETENCES,
      profile_cycles: names.PROFILE_CYCLES,
      profile_competences: names.PROFILE_COMPETENCES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      courses_wrapper: names.COURSES_WRAPPER,
      profile_competences_mention: names.PROFILE_COMPETENCES_MENTION,
    }),
    egressProfile() {
      if (!this.egress_profile_id) return [];
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    coursesWrapperList() {
      return this.courses_wrapper.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
    },
    wrapperMatterIds() {
      let list = [];
      this.coursesWrapperList.forEach((x) => {
        list = list.concat(x.egress_profile_matters);
      });
      return [...new Set(list)];
    },
    egressProfileMatterList() {
      if (!this.egress_profile_id) return [];
      return this.egress_profiles_matters.filter(
        (x) =>
          x.egress_profile == this.egress_profile_id &&
          !this.wrapperMatterIds.includes(x.id)
      );
    },
    egressProfileCycleId() {
      if (!this.egress_profile_id) return [];
      let list = [];
      this.profile_cycles.forEach((x) => {
        if (x.egress_profile == this.egress_profile_id) list.push(x.cycle);
      });
      return list;
    },
    profileCompetencesMention() {
      return this.profile_competences_mention
        .filter((x) => x.egress_profile == this.egress_profile_id)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
  },
  methods: {
    cycleName(cycle_id) {
      if (!cycle_id && !this.egress_profile_id) return "NA";
      let cycle = this.cycles.find(
        (x) =>
          x.id == cycle_id && x.egress_profiles.includes(this.egress_profile_id)
      );
      return cycle ? cycle.name : "N/A";
    },
    mentionsName(profile_competence) {
      const mentions = this.profile_competences_mention
        .filter(
          (x) =>
            x.egress_profile == profile_competence.egress_profile &&
            x.egress_profile_competences.includes(
              profile_competence.profile_competence_id
            )
        )
        .map((x) => x.name);
      if (mentions.length > 1) return mentions.join(", ");
      else if (mentions.length == 1) return mentions[0];
      else return "Común";
    },
    getMatterName(id) {
      const matter = this.matters.find((x) => x.id == id);
      return matter ? matter.name : "";
    },
    reportCompetences(competence_type_id) {
      let report = [];
      // !this.cycle_id ||
      if (!this.egress_profile_id) return [];
      let list_competences = [];
      let list = this.profile_competences.filter(
        (x) =>
          //  x.cycle == this.cycle_id &&
          x.egress_profile == this.egress_profile_id
      );

      list.forEach((element) => {
        let competence = this.competences.find(
          (x) => x.id == element.competence && x.type == competence_type_id
        );
        if (competence) {
          list_competences.push({
            id: competence.id,
            type: competence.type,
            full_sentence: competence.full_sentence,
            cycle: element.cycle,
            order: element.order,
            sub_order: element.sub_order,
            egress_profile: element.egress_profile,
            profile_competence_id: element.id,
            action: competence.action,
            content: competence.content,
            condition: competence.condition,
            verb: competence.verb,
            // name: competence.name,
          });
        }
      });
      let listmatter = this.egressProfileMatterList;
      // .filter(
      //   (x) =>
      //     // x.cycle == this.cycle_id &&
      //     x.egress_profile == this.egress_profile_id
      // );
      if (list_competences && listmatter) {
        list_competences.forEach((element) => {
          let contador = 0;
          let courses = [];
          listmatter.forEach((matter) => {
            if (
              matter.competences.find((x) => x == element.id) &&
              element.type == competence_type_id
            ) {
              contador += 1;
              courses == courses.push(matter.id);
            }
          });
          let get_cycle = null;
          let profile_cycle_order = 0;
          this.profile_cycles.forEach((profile_cycle) => {
            if (profile_cycle.cycle == element.cycle) {
              get_cycle = profile_cycle;
              profile_cycle_order = profile_cycle.order;
            }
          });
          report.push({
            id: element.id,
            full_sentence: element.full_sentence,
            action: element.action,
            content: element.content,
            condition: element.condition,
            verb: element.verb,
            cantidad: contador,
            courses: courses,
            cycle: element.cycle,
            order: element.order,
            sub_order: element.sub_order,
            profile_cycle: get_cycle,
            profile_cycle_order: profile_cycle_order,
            egress_profile: element.egress_profile,
            profile_competence_id: element.profile_competence_id,
          });
        });
      }
      // if (list && listmatter) {
      //   list.forEach((element) => {
      //     let contador = 0;
      //     listmatter.forEach((matter) => {
      //       if (
      //         matter.competences.find((x) => x == element.id) &&
      //         element.type == competence_type_id
      //       ) {
      //         contador += 1;
      //       }
      //     });
      //     report.push({
      //       id: element.id,
      //       name: element.sentence,
      //       cantidad: contador,
      //     });
      //   });
      // }
      report.sort(function (a, b) {
        if (a.profile_cycle_order > b.profile_cycle_order) return 1;
        if (a.profile_cycle_order < b.profile_cycle_order) return -1;
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        if (a.sub_order > b.sub_order) return 1;
        if (a.sub_order < b.sub_order) return -1;
        return 0;
      });
      return report;
    },
    // redirectToMatterProgram(id) {
    //   let route_data = this.$router.resolve({
    //     name: "MatterProgramView",
    //     params: {
    //       matter_id: id,
    //     },
    //   });
    //   window.open(route_data.href, "_blank");
    // },
  },
  beforeDestroy() {
    this.$store.commit(names.RESET_PRINT_ORIENTATION);
    this.$store.commit(names.RESET_TITLE_OF_THE_PRINT);
  },
  mounted() {
    this.$store.commit(names.MUTATE_PRINT_ORIENTATION, true);
  },
  created() {
    this.$hasPermissions();
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    this.$store.dispatch(names.FETCH_CYCLES);
    // .then((response) => {
    //   if (response.length > 0) this.cycle_id = response[0].id;
    // });
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE, this.egress_profile_id)
      .then((egress_profile) => {
        this.$store.commit(
          names.MUTATE_TITLE_OF_THE_PRINT,
          `${this.$getVisibleNames("manual.matriz_3", false, "Matriz 3")} - ${
            egress_profile.name
          }`
        );
        this.$store.dispatch(names.FETCH_CAREER, egress_profile.career);
      });
    this.$store.dispatch(names.FETCH_COMPETENCE_TYPES, this.institution_id);
    this.$store.dispatch(names.FETCH_PROFILE_CYCLES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_MATTERS, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_COMPETENCES, this.egress_profile_id);
    this.$store.dispatch(names.FETCH_STUDY_UNITS_BY_CICLE_AND_PROFILE, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_COURSES_WRAPPER, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES_MENTION, {
      egress_profile_id: this.egress_profile_id,
    });
  },
};
</script>

<style scoped>
.amount-courses {
  width: 10%;
}
.matrix3-report-container {
  padding: 0.2rem !important;
  page-break-before: auto !important;
  page-break-after: auto !important;
  page-break-inside: avoid !important;
}
.title {
  text-align: center;
  margin-bottom: 1rem;
}
.competences,
.institution-competences {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 0.1rem;
  padding-left: 0.5rem;
  padding: 0.6rem 0;
  background-color: var(--primary-color);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.institution-competences {
  margin-top: 0.9rem;
}
.competences-table,
.institution-competences-table {
  border: 1px solid #c7c7c7;
  width: 100%;
}
.competence-container {
  border-bottom: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-left: 1px solid #c7c7c7;
  border-radius: 10px;
  padding-bottom: 0.1rem;
  margin-top: 1.5rem;
  box-shadow: 1px 1px 5px #9cb0b1;
}
.competence-header {
  background-color: var(--primary-color);
}
.competence-caption,
.competence-caption-title {
  padding-right: 1rem;
  padding-left: 1rem;
  width: fit-content;
  text-align: left;
}
.competence-caption {
  padding: 0.4rem 1rem;
}
.competence-courses {
  text-align: center;
  padding-right: 2rem;
}
.amount {
  padding: 0 1rem;
}
.competences-row {
  padding-left: 0.2rem;
}
.competences-row:nth-child(odd) {
  background-color: #e8e8e8;
}
.competence-matters-div {
  background-color: var(--secondary-color);
  color: white;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  border-radius: 3px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
  max-width: fit-content;
}
.competence-matters-container {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  flex-wrap: wrap;
  margin: 0.8em 0;
}
.less-competence-matters-div {
  background-color: var(--secondary-color);
  color: white;
  margin: 0.1em 0.1em;
  padding: 4px 8px;
  border-radius: 3px;
  flex-grow: 1;
  font-size: var(--primary-font-size);
  text-align: center;
  max-width: fit-content;
}
.profiles-container {
  width: 100%;
}
.profiles {
  background-color: var(--secondary-color);
  border-radius: 4px;
  padding: 0 2em;
  width: 100%;
  font-size: 11pt;
  text-align: center;
}
.text-white {
  text-align: left !important;
}
.th-class {
  background-color: var(--primary-color) !important;
  vertical-align: middle !important;
  text-align: center !important;
}
.popover-btn .badge {
  border: none !important;
}
</style>